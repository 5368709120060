import {
  ApolloClient,
  DocumentNode,
  InMemoryCache,
  MutationOptions,
  NormalizedCacheObject,
  OperationVariables,
} from "@apollo/client"
import { useEffect, useRef, useState } from "react"
import { APOLLO_CACHE_OPTIONS, RESULTS_PER_PAGE } from "src/constants"
import { handleError } from "src/helpers/bugsnag"

// client-side singleton:
let client: ApolloClient<NormalizedCacheObject>
export const getClient = () => {
  if (!client && typeof window !== "undefined") {
    client = new ApolloClient({
      uri: process.env.GATSBY_API_ENDPOINT,
      cache: new InMemoryCache(APOLLO_CACHE_OPTIONS),
    })
  }
  return client
}

const REFS = {
  page: 0,
  count: 0,
}

export interface DataWithMeta extends Record<string, any> {
  meta?: {
    count: number
  }
}

export const useClientQuery = <TData extends DataWithMeta, TVariables = OperationVariables>(
  query: DocumentNode,
  vars?: TVariables,
  paginate?: string
) => {
  const refs = useRef(REFS)
  const [loading, setLoading] = useState(Boolean(vars))
  const [error, setError] = useState<Error>()
  const [data, setData] = useState<TData>()

  const get = async (page: number) => {
    refs.current.page = page

    const variables = {
      ...vars,
    }
    if (paginate) {
      variables["page"] = page
      variables["perPage"] = RESULTS_PER_PAGE
    }

    setLoading(true)
    setError(null)
    try {
      const result = await getClient().query<TData>({ query, variables })
      if (paginate && page > 1) {
        setData({ ...data, [paginate]: [...data[paginate], ...result.data[paginate]] })
      } else {
        setData(result.data)
        if (result.data.meta) {
          refs.current.count = result.data.meta.count
        } else if (paginate) {
          handleError(new Error("You must select a `meta.count` in your query in order to paginate"))
        }
      }
    } catch (err) {
      setError(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    setData(null)
    if (vars) {
      get(1)
    }
  }, [JSON.stringify(vars)]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) {
      handleError(error)
    }
  }, [error])

  const fetchMore = () => {
    if (loading) {
      return
    }

    if (!paginate) {
      throw new Error("You must pass the result data key for the array to be paginated, using the `paginate` argument")
    }

    if (data) {
      const { length } = data[paginate]
      const complete = length === refs.current.count
      const next = refs.current.page * RESULTS_PER_PAGE > length
      if (complete || next) {
        return
      }
    }

    get(refs.current.page + 1)
  }

  return {
    loading,
    error,
    data,
    fetchMore,
  }
}

export const useClientMutation = <TData, TVariables = OperationVariables>(mutation: DocumentNode) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error>()
  const [data, setData] = useState<TData>()

  const execute = (variables: TVariables, context?: any) => {
    setLoading(true)
    const options: MutationOptions<TData, TVariables> = {
      mutation,
      variables: {
        ...variables,
        domainId: process.env.GATSBY_DOMAIN_ID,
      },
      context,
    }

    getClient()
      .mutate<TData, TVariables>(options)
      .then((res) => {
        if (res.errors) {
          // TODO: can this happen? does it not directly go to catch()?
          throw res.errors
        }
        setData(res.data)
      })
      .catch((err) => {
        handleError(err)
        setError(err)
      })
      .then(() => {
        setLoading(false)
      })
  }

  return { loading, error, data, execute }
}
